<template>
  <span>{{ value > 0 ? value : "" }}</span>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "HideZero",
  props: ["value"],
});
</script>
