<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="560"
    style="z-index: 290"
    @keydown.esc="cancel"
  >
    <v-alert
      colored-border
      border="left"
      prominent
      :color="color"
      :icon="icon"
      class="mb-0"
    >
      <v-row align="center">
        <v-col class="grow"
          ><div v-html="message"></div>
          <v-text-field
            :label="label"
            v-model="value"
            autofocus
            @keydown.enter="agree"
          />
        </v-col>

        <v-col class="shrink">
          <v-btn
            block
            text
            outlined
            :color="color"
            class="mb-2"
            @click="agree"
            :disabled="!value && !optional"
            >ja</v-btn
          >
          <v-btn block @click="cancel" outlined text plain>nein</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-dialog>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    label: "Wert",
    value: "",
    color: "primary",
    icon: "mdi-information",
    timeout: null,
    optional: false,
  }),
  methods: {
    open(args) {
      this.dialog = true;
      window.clearTimeout(this.timeout);
      if (args.message) {
        this.message = args.message;
      }
      if (args.color) {
        this.color = args.color;
      }
      if (args.icon) {
        this.icon = args.icon;
      }
      if (args.label) {
        this.label = args.label;
      }
      if (args.optional) {
        this.optional = args.optional;
      }

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve(this.value);
      this.timeout = window.setTimeout(() => (this.value = ""), 10000);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.timeout = window.setTimeout(() => (this.value = ""), 10000);
      this.dialog = false;
    },
  },
});
</script>
