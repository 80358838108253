<template>
  <div v-html="markdown" class="markdown"></div>
</template>
<script>
import { defineComponent } from "vue";
var md = require("markdown-it")({
  breaks: true,
  html: false,
  linkify: true,
});
export default defineComponent({
  name: "Markdown",
  props: ["value"],
  computed: {
    markdown() {
      if (this.value) {
        let doc = new DOMParser().parseFromString(this.value, "text/html");
        return md.render(doc.body.textContent);
      }
      return "";
    },
  },
});
</script>
<style scoped>
.markdown :deep(h1),
.markdown :deep(h2),
.markdown :deep(h3),
.markdown :deep(h4) {
  font-size: medium !important;
  font-weight: bold !important;
}
.markdown :deep(> ul) {
  padding-bottom: 2ex;
}
</style>
